import React from 'react'
import Layout from '../layout/basic.js'

import '../css/business/style.scss'
import "animate.css/animate.min.css";

import Config from '../config'
import ScrollAnimation from 'react-animate-on-scroll';

const BusinessPage = () => (
  <Layout currentName="business">
    <section className="business">

      <article className="hero header-hero has-text-centered">
        <div className="hero-body">
          <div className="container">
            <h1 className="title">事業内容</h1>
            <h2 className="subtitle">BUSINESS</h2>
          </div>
        </div>
      </article>

      <article className="main-contents">
        <div className="container columns is-multiline">
          <div className="column is-12 has-text-centered">
            <div className="columns">
              <div className="column is-4 is-offset-4">
                <p>当社の展開する事業をご紹介します。</p>
              </div>
            </div>
          </div>

          <div className="column is-12">
            <div className="columns is-multiline">
              <header className="column is-6 is-offset-3 has-text-centered">
                <h1>
                  <span className="marked-by-coveredline marker-is-blue">
                    システム・エンジニアリング事業
                  </span>
                </h1>
              </header>
              <div className="column is-8 is-offset-2">
                <p>
                  当社の代表は、長年に渡りシステム・エンジニアとして数多くのＩＴシステムの開発に携わってきた技術者です。
                  当社はその経験と知見を活かし、主要事業としてＩＴ技術を提供する事業を展開しています。
                  ただ、同業他社に比べて中小企業、小規模事業者の方々の事業にもＩＴを活用して頂けるようデザインされています。</p>
              </div>
              <div className="column is-4">
                <ScrollAnimation animateIn="fadeIn"
                  delay={100}
                  animateOnce={true}>
                  <div className="card">
                    <div className="card-content">
                      <div className="header">
                        <h2 className="title">
                          <span className="marked-by-coveredline marker-is-yellow">
                            &nbsp;&nbsp;MISSION&nbsp;&nbsp;
                          </span>
                        </h2>
                        <h3 className="subtitle">
                          <span className="marked-by-underline marker-is-blue">
                            あなたのビジネスをＩＴでより輝かせること
                          </span>
                        </h3>
                      </div>
                      <div className="content">
                        <p>
                          ＩＴは、今や人々の生活に欠かすことのできない技術になりました。
                          しかし、この技術を事業に活用することは容易ではありません。
                          特に十分な知見を持つ人材は限られ、時に人材確保はＩＴ活用の大きな障壁となります。
                        </p>
                        <p>
                          我々はこの状況を打破すべく、事業規模を問わずＩＴを活用して頂けるように技術提供を行います。
                          その成果として、あなたの事業の価値を最大にすることが我々の使命です。
                        </p>
                      </div>
                    </div>
                  </div>
                </ScrollAnimation>
              </div>
              <div className="column is-4">
                <ScrollAnimation animateIn="fadeIn"
                  delay={300}
                  animateOnce={true}>
                  <div className="card">
                    <div className="card-content">
                      <div className="header">
                        <h2 className="title">
                          <span className="marked-by-coveredline marker-is-yellow">
                            &nbsp;&nbsp;VISION&nbsp;&nbsp;
                          </span>
                        </h2>
                        <h3 className="subtitle">
                          <span className="marked-by-underline marker-is-blue">
                            あらゆる事業で、当たり前にＩＴが活用される世界の実現
                          </span>
                        </h3>
                      </div>
                      <div className="content">
                        <p>
                          我々がその使命を遂げてたどり着こうとする未来。
                          それは、あらゆる事業で当たり前にＩＴが活用される世界です。
                        </p>
                        <p>
                          ＩＴが貢献できる範囲は極めて広く、様々な社会問題の解決にも貢献できます。
                          しかし、導入しても一過性の施策になってしまうなど、広く利用されるためには課題もあります。
                          我々はこの課題を解決し、状況を覆したいのです。
                        </p>
                      </div>
                    </div>
                  </div>
                </ScrollAnimation>
              </div>
              <div className="column is-4">
                <ScrollAnimation animateIn="fadeIn"
                  delay={500}
                  animateOnce={true}>
                  <div className="card">
                    <div className="card-content">
                      <div className="header">
                        <h2 className="title">
                          <span className="marked-by-coveredline marker-is-yellow">
                            &nbsp;&nbsp;VALUE&nbsp;&nbsp;
                          </span>
                        </h2>
                        <h3 className="subtitle">
                          <ul>
                            <li>
                              <span className="marked-by-underline marker-is-blue">利用可能な価格</span>
                            </li>
                            <li>
                              <span className="marked-by-underline marker-is-blue">長期に渡って事業をサポート</span>
                            </li>
                            <li>
                              <span className="marked-by-underline marker-is-blue">事業の持つ世界観を第一に</span>
                            </li>
                          </ul>
                        </h3>
                      </div>
                      <div className="content">
                        <p>
                          MISSION&nbsp;を遂げるため、サービスを多くの事業者の方々に利用して頂ける価格で提供することをお約束します。<br/>
                          VISION&nbsp;を具現化させるため、 長期に渡ってＩＴ活用を支援します。<br/>
                          そして、ＩＴはあなたのビジネスに貢献する道具であるべきです。ＩＴを利用するがために、無理なデジタル化はしません。
                        </p>
                      </div>
                    </div>
                  </div>
                </ScrollAnimation>
              </div>

              <div className="bridge column is-6 is-offset-3 has-text-centered">
                <h2><span className="laud-text">ご提供可能なサービス</span></h2>
                <div>
                  <ScrollAnimation animateIn="fadeIn"
                    delay={200}
                    animateOnce={true}>
                    <div className="card">
                      <div className="card-content">
                        <div className="header">
                          <h2 className="title">
                            <span className="marked-by-coveredline marker-is-orange">
                              システム導入サービス
                            </span>
                          </h2>
                        </div>
                        <div className="content">
                          <p>
                            御社の事業にＩＴシステムを導入させて頂くサービスです。
                            問題、課題を伺い、最適なシステムのご提案から開発、導入までを一括で承ります。
                          </p>
                          <p>
                            我々は事業の描く世界観を大切に、無理なデジタル化は行いません。
                            また、新規開発を最小限にすることで、低価格と短納期を実現します。
                          </p>
                        </div>
                        <div className="guide">
                          <p className="title">【&nbsp;ご利用の流れ&nbsp;】</p>
                          <ol>
                            <li>
                              <span>ご相談、ご提案</span><br/>
                              <span className="description">
                                まずは解決するべき問題、課題、ご予算などを伺います。
                                その内容から、担当者が最適なご提案をさせて頂きます。
                              </span>
                            </li>
                            <li>
                              <span>導入</span><br/>
                              <span className="description">
                                ご提案の内容やお見積りにご納得頂けましたら、契約を締結させて頂きます。
                                その後、必要な対応を行い、システムの導入を行います。
                              </span>
                            </li>
                            <li>
                              <span>サポート</span><br/>
                              <span className="description">
                                導入後、運用に伴って発生するご不明点があれば、サポートさせて頂きます。
                                お気軽にご連絡ください。
                              </span>
                            </li>
                          </ol>
                        </div>
                      </div>
                    </div>
                  </ScrollAnimation>
                </div>

                <div>
                  <ScrollAnimation animateIn="fadeIn"
                    delay={200}
                    animateOnce={true}>
                    <div className="card">
                      <div className="card-content">
                        <div className="header">
                          <h2 className="title">
                            <span className="marked-by-coveredline marker-is-yellow">
                              計画書作成サービス
                            </span>
                          </h2>
                        </div>
                        <div className="content">
                          <p>
                            御社の事業の発展に向けてシステムをどのように貢献させていくか、
                            それに伴う投資をどのようにしていくか、
                            既存資産の調査、分析などを行いながら検討した結果を計画書にまとめさせて頂くサービスです。
                          </p>
                          <p>
                            システムやツールを一過性のものにせず、事業に不可欠な資産とするお手伝いをします。
                          </p>
                        </div>
                        <div className="guide">
                          <p className="title">【&nbsp;ご利用の流れ&nbsp;】</p>
                          <ol>
                            <li>
                              <span>目的の決定</span><br/>
                              <span className="description">
                                まず、何のために計画書を作成するのか。
                                これを明確にさせて頂くため、担当者が相談をさせて頂き、計画書の目的を決定します。
                              </span>
                            </li>
                            <li>
                              <span>調査、分析</span><br/>
                              <span className="description">
                                目的に応じてお見積りをさせて頂きます。ご納得頂けましたら、契約を締結させて頂きます。
                                その後、計画書の作成に向けて調査、分析を行います。
                              </span>
                            </li>
                            <li>
                              <span>計画書作成、納品</span><br/>
                              <span className="description">
                                調査、分析を行った結果を元に、計画書を作成します。
                                完成後、お会いして内容の説明をさせて頂いた上で、納品させて頂きます。
                              </span>
                            </li>
                          </ol>
                        </div>
                      </div>
                    </div>
                  </ScrollAnimation>
                </div>
              </div>
              <footer className="column is-8 is-offset-2 has-text-centered">
                <ScrollAnimation animateIn="fadeIn"
                  delay={100}
                  animateOnce={true}>
                  <p>
                    ご相談をご希望の方は上記メールアドレスまでご連絡下さい。
                    折り返しご連絡させて頂き、詳細なサービス内容などをご案内させて頂きます。
                  </p>
                  <p>
                    なお、実現したいことのイメージなどがございましたらお書き添え下さい。
                  </p>
                  <a href={`mailto:${Config.mail.sesDivision}`}>{Config.mail.sesDivision}</a>
                  <p className="note">
                    こちらは事業を営まれている企業、個人の方を対象としたサービスです。
                    一般の方のＩＴに関するご相談は受け付けておりませんので、
                    あらかじめご了承下さい。
                  </p>
                </ScrollAnimation>
              </footer>
            </div>
          </div>

        </div>
      </article>

    </section>
  </Layout>
);
export default BusinessPage;
